@import "../../styles/hamburgers/hamburgers";
@import "../../styles/functions/rem-calc";
@import "../../styles/vars";
@import "../../styles/vars-colors";
@import "../../styles/vars-media-queries";


.mega-menu-container {
  --transition-duration: .125s;
  --transition-timing: ease-out;
  --level-1-background: rgba(52, 72, 89, 0.67);
  --level-1-background-mobile: #273440;
  --level-2-background: rgba(52, 72, 89, 1);

  .menu-container .menu-brand {
    margin-right: 0;
  }

  .language-switch {
    display: inline-block;
  }

  .language-switch-link {
    color: rgba(255,255,255,.75);
    strong {
      /* highligt for the chosen language */
      color: white;
    }
  }
}

.mega-menu {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  a {
    display: block;
    margin: 0;
    line-height: 3.5rem;
    white-space: nowrap;
  }

  a:hover,
  a:active {
    text-decoration: underline;
  }

  li::before {
    content: unset;
  }

  button {
    white-space: nowrap;
    text-align: start;
    display: block;
    width: 100%;
    .icon-chevron-down {
      display: inline-block;
      transform: rotate(270deg);
    }
  }

  button:hover {
    background-color: var(--level-1-background);
  }

  .active > button {
    background-color: var(--level-1-background);
    color: #00b6c8 !important;
    .icon-chevron-down {
      transform: rotate(90deg);
    }
  }

}

/* desktop */
@media #{$mq-min1152} {
  /* hide mobile navigation elements */
  .heading,
  .hamburger,
  .back-button {
    display: none !important;
  }

  .item-level-0.active .list-level-1::before {
    /*
    Backdrop
    */
    content: "";
    display: block;
    background-color: rgba(0, 0, 0, .75);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    position: absolute;
    top: -3.75rem;
    left: -100vw;
    right: -100vw;
    bottom: -3.75rem;
    z-index: -1;
  }

  .item-level-0 {
    > button .icon-chevron-down {
      transform: rotate(0deg);
    }
    &.active > button .icon-chevron-down {
      transform: rotate(180deg);
    }
  }

  .list-level-1 {
    pointer-events: none;
    position: absolute;
  }

  .item-level-1 {
    background-color: var(--level-1-background);
    position: relative;

    button,
    a {
      padding-right: 3rem;
    }
  }


  .item-level-1.active {
    > button {
      background-color: var(--level-2-background);
    }
  }

  .list-level-2 {
    position: absolute;
    top: 0;
    z-index: -1;

    a {
      padding-left: 2rem;
    }
  }

  .item-level-2 {
    background-color: var(--level-2-background);
  }
}

/* desktop - animations */
@media #{$mq-min1152} {
  .item-level-1 {
    opacity: 0;
    transition: opacity var(--transition-duration) var(--transition-timing);
  }

  .item-level-0.active .list-level-1 {
    pointer-events: all;
    .item-level-1 {
      opacity: 1;
      transition-delay: calc(var(--animation-order) * 25ms);
    }
  }


  .item-level-1.active .list-level-2 {
    transition-duration: var(--transition-duration);
    opacity: 1;
    left: 100%;
  }

  .list-level-2 {
    transition: all 0 var(--transition-timing); /* transition-duration when hiding is 0 */
    opacity: 0;
    left: 0;
  }
}

/* mobile navi two column */
@media #{$mq-max1152} {
  .mega-menu-container {
    .menu-container {
      margin: 0;
      display: block;
    }

    .hamburger {
      line-height: 4rem;
      padding: 0 1rem;
    }

    .language-switch {
      display: none;
    }

    &-active {
      height: 100vh;
      overflow: hidden auto;
      background-color: rgba(0, 0, 0, 0.85) !important;
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);

      .menu-container {
        display: block;
      }

      .kontakt {
        display: block;
        padding: 0 0.75rem 0 5vw;
      }

      .language-switch {
        display: block;
        padding: 0 .25rem;
      }
    }
  }

  .mega-menu {
    background-color: transparent;

    li {
      width: 100vw;
    }

    a,
    button,
    .list-level-2 a {
      padding: 0 0.75rem 0 5vw;
    }

    /* overriding presets from _lists.scss */
    li > ul::before {
      content: unset;

    }

    .list-level-1 {
      top: 0;
      width: 100vw;
      min-height: 100vh;

      z-index: 1;
      background-color: var(--level-1-background-mobile);
    }

    .item-level-1 {
      background-color: transparent;
      position: relative;
      &.active > button {
        background-color: var(--level-2-background);
      }
    }

    li button .icon-chevron-down {
      display: inline-block;
      transform: rotate(-90deg);
    }

    .list-level-2 {
      display: block;
      max-width: 62vw; /* golden Ratio */
      left: unset;
      z-index: 2;
      width: 67vw;
      background-color: var(--level-2-background);
      position: absolute;
      top: 0;
      .back-button {
        display: none;
      }
      .heading {
        display: none;
      }
    }

    .heading {
      display: block;
      font-size: larger;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      margin-bottom: 1rem;
    }

    .heading {
      display: block;
      font-size: larger;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      margin-bottom: 1rem;
    }

    .back-button {
      font-size: small;
      background-color: rgba(0, 0, 0, 0.5);
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);

      .icon-chevron-down {
        display: inline-block;
        transform: rotate(90deg);
        margin-right: .5rem;
      }
    }
  }
}

/* mobile navi - animations */
@media #{$mq-max1152} {
  .mega-menu-container {
    .extra-controls {
      height: 0;
      overflow: hidden;
    }

    .kontakt {
      transform: translateX(-10vw);
      transition: transform var(--transition-duration) var(--transition-timing);
      transition-delay:  calc(var(--animation-order) * 25ms);
    }

    &-active {
      .extra-controls {
        height: unset;
      }
      .kontakt {
        height: auto;
        transform: translateX(0);
      }
    }
  }

  .mega-menu {
    left: -100vw;
    height: 0;
    overflow: hidden;
    position: relative;
    &-active {
      left: 0;
      overflow: inherit;
      z-index: 1;
      height: unset;
    }

    .item-level-0 > button,
    .item-level-0 > a {
      transform: translateX(-10vw);
      transition: transform var(--transition-duration) var(--transition-timing);
      transition-delay:  calc(var(--animation-order) * 25ms);
    }

    &-active {
      display: block;
      .item-level-0 > button,
      .item-level-0 > a {
        transform: translateX(0);
      }
    }

    .item-level-0.active .list-level-1 {
      transform: translateX(0);
    }

    .list-level-1 {
      position: absolute;
      transform: translateX(100vw);
      transition: transform var(--transition-duration) var(--transition-timing);
    }

    .item-level-1.active > .list-level-2 {
      transform: translateX(38vw);
      transition-duration: var(--transition-duration);
    }

    .list-level-2 {
      transform: translateX(100vw);
      transition: transform 0 var(--transition-timing);
    }
  }
}

/* mobile navi one column */
@media (max-width: 500px) {
  .mega-menu {
    .item-level-1 {
      position: static;
    }

    .item-level-1.active .list-level-2 {
      transform: translateX(0);

    }

    .list-level-2 {
      max-width: 100vw;
      margin-top: 0;
      min-height: 100vh !important;
      width: 100vw;
      transition-duration: var(--transition-duration);

      .heading,
      .back-button {
        display: block;
      }
    }

  }
}
